import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "./constants/constants";

function App() {
  const [posts, setPosts] = useState([]);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [editingId, setEditingId] = useState(null);
  const [editTitle, setEditTitle] = useState("");
  const [editContent, setEditContent] = useState("");

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = () => {
    axios
      .get(`api/posts`)
      .then((response) => {
        setPosts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching posts:", error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Create a new post
    axios
      .post(`api/posts`, { title, content })
      .then((res) => {
        setPosts([...posts, res.data]);
        setTitle("");
        setContent("");
      })
      .catch((err) => console.error("Error creating post:", err));
  };

  const handleDelete = (id) => {
    axios
      .delete(`api/posts/${id}`)
      .then(() => {
        setPosts(posts.filter((post) => post._id !== id));
      })
      .catch((err) => console.error("Error deleting post:", err));
  };

  const handleEdit = (post) => {
    setEditingId(post._id);
    setEditTitle(post.title);
    setEditContent(post.content);
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    axios
      .put(`api/posts/${editingId}`, {
        title: editTitle,
        content: editContent,
      })
      .then((res) => {
        // Update the post in the state array
        setPosts(posts.map((p) => (p._id === editingId ? res.data : p)));
        // Reset edit mode
        setEditingId(null);
        setEditTitle("");
        setEditContent("");
      })
      .catch((err) => console.error("Error updating post:", err));
  };

  return (
    <div style={{ maxWidth: "600px", margin: "auto", padding: "20px" }}>
      <h1>Posts</h1>

      {/* Form to create a new post */}
      <form onSubmit={handleSubmit} style={{ marginBottom: "30px" }}>
        <h2>Create a New Post</h2>
        <div style={{ marginBottom: "10px" }}>
          <input
            type='text'
            placeholder='Title'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={{ width: "100%", padding: "8px", boxSizing: "border-box" }}
            required
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <textarea
            placeholder='Content'
            value={content}
            onChange={(e) => setContent(e.target.value)}
            style={{
              width: "100%",
              padding: "8px",
              boxSizing: "border-box",
              height: "80px",
            }}
            required
          />
        </div>
        <button type='submit' style={{ padding: "10px 15px" }}>
          Create Post
        </button>
      </form>

      {/* List of posts */}
      {posts.map((post) => (
        <div
          key={post.id}
          style={{
            border: "1px solid #ccc",
            padding: "15px",
            marginBottom: "15px",
          }}
        >
          {editingId === post._id ? (
            // Edit form for a specific post
            <form onSubmit={handleUpdate}>
              <input
                type='text'
                value={editTitle}
                onChange={(e) => setEditTitle(e.target.value)}
                style={{
                  width: "100%",
                  padding: "8px",
                  marginBottom: "10px",
                  boxSizing: "border-box",
                }}
              />
              <textarea
                value={editContent}
                onChange={(e) => setEditContent(e.target.value)}
                style={{
                  width: "100%",
                  padding: "8px",
                  marginBottom: "10px",
                  boxSizing: "border-box",
                  height: "80px",
                }}
              />
              <button
                type='submit'
                style={{ padding: "10px 15px", marginRight: "10px" }}
              >
                Update
              </button>
              <button
                type='button'
                onClick={() => setEditingId(null)}
                style={{ padding: "10px 15px" }}
              >
                Cancel
              </button>
            </form>
          ) : (
            <>
              <h2>{post.title}</h2>
              <p>{post.content}</p>
              <small>
                Created: {new Date(post.createdAt).toLocaleString()}
              </small>
              <br />
              <button
                onClick={() => handleEdit(post)}
                style={{
                  padding: "5px 10px",
                  marginRight: "10px",
                  marginTop: "10px",
                }}
              >
                Edit
              </button>
              <button
                onClick={() => handleDelete(post._id)}
                style={{ padding: "5px 10px", marginTop: "10px" }}
              >
                Delete
              </button>
            </>
          )}
        </div>
      ))}

      {posts.length === 0 && <p>No posts found.</p>}
    </div>
  );
}

export default App;
